import { combineReducers } from "redux";
import { coursesReducer } from "./coursesReducer";
import { socketReducer } from "./socketReducer";
import { firebaseReducer } from "react-redux-firebase";
import { authReducer } from "./authReducer";
import { uiReducer } from "./uiReducer";
import { classReducer } from "./classReducer";

const rootReducer = combineReducers({
  authState: authReducer,
  classState: classReducer,
  socketState: socketReducer,
  courseState: coursesReducer,
  firebaseState: firebaseReducer,
  uiState: uiReducer,
});

export default rootReducer;
