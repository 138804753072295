import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-full.svg";
import { ReactComponent as RoblesLogo } from "../../assets/images/robles-logo.svg";
/* import logoBlanco from "../../assets/images/logo-blanco.png"; */

const LalaFooter = () => {
  return (
    <footer>
      <Row className="bg-dark text-light pt-5 pb-5">
        <br></br>
        <Container>
          <Row className="align-items-center">
            <Col>
              <FontAwesomeIcon
                icon={faInstagram}
                color="#efba81"
                size="lg"
                className="mr-3"
              />
              @lalamadeira <br />
              <FontAwesomeIcon
                icon={faEnvelope}
                color="#efba81"
                size="lg"
                className="mr-3"
              />
              lala@lauramadeira.com
            </Col>
            <Col className="text-center">
              <OsherLogo style={{ height: "50px" }} />
            </Col>
            <Col className="text-center">
              <RoblesLogo style={{ height: "90px" }} />
            </Col>
          </Row>
          <br></br>
          <Row>
            <p style={{ fontSize: "14px" }}>
              Copyright © 2020 Laura Madeira - All Rights Reserved.
            </p>
          </Row>
        </Container>
      </Row>
    </footer>
  );
};

export default LalaFooter;
