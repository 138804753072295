import React, { Component } from "react";
import MainNavbar from "../components/navbar/navbar";
import Home from "../components/homepage";
import Membersite from "../components/memberpage";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import LoadingPage from "../components/loadingpage";
import SuccessSnackbar from "../components/snackbar/successSnackbar";
/* import Checkout from "../components/checkout"; */
import Agenda from "../components/agenda";
import CourseDetail from "../components/courses/courseDetail";
import Store from "../components/store/store";
import StoreItemDetail from "../components/store/storeItemDetail";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebaseState.auth);
  if (!isLoaded(auth)) return <LoadingPage></LoadingPage>;
  return children;
}
class App extends Component {
  render() {
    return (
      <AuthIsLoaded>
        <SuccessSnackbar />
        <MainNavbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/agenda" component={Agenda} />
          <Route path="/membersite" component={Membersite} />
          <Route path="/course/:id" component={CourseDetail} />
          <Route path="/store/:itemName" component={StoreItemDetail} />
          <Route path="/store" component={Store} />
          {/* <Route path="/checkout" component={Checkout} /> */}
        </Switch>
      </AuthIsLoaded>
    );
  }
}

export default App;
