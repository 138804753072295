import { createAction } from "redux-actions";
import API from "../utils/api";

export const courseActions = {
  GET_COURSES: createAction("GET_COURSES"),
  GET_COURSES_FINISHED: createAction("GET_COURSES_FINISHED", (courses) => {
    return {
      courses,
    };
  }),
  GET_COURSES_ERROR: createAction("GET_COURSES_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getCoursesAction = () => async (dispatch, getState, getFirebase) => {
  dispatch(courseActions.GET_COURSES());
  API.get("/courses")
    .then((res) => {
      dispatch(courseActions.GET_COURSES_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(courseActions.GET_COURSES_ERROR(error));
    });
};
