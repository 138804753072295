import React, { useState } from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const FirstSection = () => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const [open, setOpen] = useState(false);
  return (
    <Row>
      <Container>
        <Row>
          <Col md className="text-center">
            <iframe
              className="mobile"
              title="Video Membresia"
              width="100%"
              height="450"
              src="https://player.vimeo.com/video/434800835"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <br />
            <h2 className="title ">Una nueva forma de acompañarte...</h2>
            <p>
              La membresía del sistema OSHER tiene como objetivo que te sientas
              acompañado para sostener lo aprendido y vivir en tu vibración más
              elevada. Este es el camino de la felicidad.
            </p>
            <p>
              Para ello he preparado contenidos que estarán disponible siempre
              que los necesites y contribuirán a que tengas a mano mensajes
              claros y simples.
            </p>
            <p>
              Este sistema se basa en mi libro &quot;Té del Alma&quot;, donde
              nos centraremos en las intenciones de cada luna nueva. Esto te
              servirá para conocerte y aceptarte tal cual eres y entender que{" "}
              <b>“todo se trata de tí”</b>. Cada mes tendrás clases, ejercicios
              y recordatorios para elevar tu vibración. También aprenderás
              conceptos de Kabbalah y realizaremos meditaciones.
            </p>
            <p>
              Las clases pueden ser en vivo que te permitirán participar o sino
              quedarán grabadas para que puedas verlas o escucharlas las veces
              que quieras y en el momento que dispongas.
            </p>
            <Row>
              <Col lg>
                <h2 className="title mainColorText">Beneficios</h2>
                <ul>
                  <li>
                    Pasarás de pensar a sentir y dejarás que tu corazón te guíe
                  </li>
                  <li>
                    Encontrarás tu ritmo y esto te llevará a un estado de paz
                  </li>
                  <li>
                    Aprenderás a amarte, respetarte y priorizarte para después
                    brindarte a otros
                  </li>
                  <li>
                    Encontrarás tu coherencia que te permitirá encontrar tu
                    propósito.
                  </li>
                  <li>Sentirte acompañado en este camino</li>
                  <li>Disponer de las herramientas en forma permanente</li>
                </ul>
              </Col>
              <Col lg>
                <h2 className="title mainColorText">
                  Forma de pago y descuentos
                </h2>
                <p>
                  Desde Argentina podes pagar con{" "}
                  <b>Mercado Pago con un valor de </b> <br />{" "}
                  <b style={{ fontSize: "20px" }}>$3080</b> mensuales. <br />
                  Desde el exterior podras pagar con{" "}
                  <b>PayPal con un valor de </b> <br />{" "}
                  <b style={{ fontSize: "20px" }}>USD29</b> mensuales.
                </p>
                <p>
                  Siendo miembro OSHER tendrás descuentos en talleres, retiros,
                  tienda online y estadías en Robles de Besares.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center pt-4">
              <Col className="text-center" md={10}>
                <p style={{ fontSize: "22px" }}>
                  <b>
                    Subscribirte es totalmente GRATIS, de esta manera podras ver
                    TODO el contenido de las clases, sus fechas y los temas que
                    se hablarán a lo largo de todo el año.
                  </b>
                </p>
                <Link to="/membersite/register">
                  <Button className="mainButton mb-4" size="lg">
                    Subscribirse Gratis!
                  </Button>
                </Link>
                <br />
                <p style={{ fontSize: "22px" }}>
                  <b>
                    {" "}
                    Para participar de las clases en vivo y su contenido
                    exclusivo, podrás pagar la Membresia OSHER al momento de
                    querer ver el contenido de un tema.
                  </b>
                </p>
                {isLoaded(auth) && !isEmpty(auth) ? (
                  <Link to="/membersite/payment-info">
                    <Button
                      className="btn-light"
                      size="lg"
                      style={{
                        border: "1.5px solid rgb(239, 186, 129)",
                      }}
                    >
                      Ser miembro
                      <OsherLogo className="ml-2" style={{ height: "30px" }} />
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className="btn-light"
                    size="lg"
                    style={{
                      border: "1.5px solid rgb(239, 186, 129)",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Ser miembro
                    <OsherLogo className="ml-2" style={{ height: "30px" }} />
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          ¿Queres ser miembro OHSER?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <p>
              <b>Para poder ser miembro OSHER,</b> deberás primero hacer una
              cuenta de subscripción gratis.
            </p>
            <Link to="/membersite/register">
              <Button className="mainButton mb-4" size="md">
                Subscribirse Gratis!
              </Button>
            </Link>
            <p>¿Ya estas subscripto?</p>
            <Link to="/membersite/login">
              <Button className="mainButton mb-4" size="md">
                Iniciar sesión!
              </Button>
            </Link>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Row>
  );
};

export default FirstSection;
