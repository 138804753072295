import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import StoreItem from "./storeItem";
import teDelAlmaImage from "../../assets/products/teDelAlma.png";
import almaDeRobleImage from "../../assets/products/almaDeRoble.png";
import agenda2021Image from "../../assets/products/agenda2021.png";
import cuadernoImage from "../../assets/products/agenda23.jpeg";
const Store = () => {
  return (
    <Container fluid className="pt-5 mt-5 px-5">
      <Row>
        <Col>
          <h3>Tienda</h3>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={3} md={4} sm={6} className="mb-2">
          <StoreItem
            title="Alma de Roble"
            description="Conocé mi historia."
            image={almaDeRobleImage}
            secondaryDescription="Lala nos cuenta en este libro cómo su alma la llevó a tomar el verdadero camino y a descubrir que lo escencial es invisible a los ojos. Gracias a esta transformación, hoy puede inspirar a otros a que busquen nuevos caminos en su vida, y así crear un mundo mejor."
          />
        </Col>
        <Col lg={3} md={4} sm={6} className="mb-2">
          <StoreItem
            title="Té del Alma"
            description="Un libro que te acompaña."
            image={teDelAlmaImage}
            secondaryDescription="Mi intención es acompañarte en tu proceso de evolución personal y también que puedas invitar a otros a iniciar este camino. Esta es la principal razón de este libro. Capítulo a capítulo, encontrarás tips que te permitirán mejorar algunos aspectos donde te sientas estancado. Llegar a un estado de paz puede ser más simple que sostenerlo, por eso es importante realizar los ejercicios recomendados para desarrollar la disciplina. Cuando la frase 'Todo se trata de mi' sea real para tí habrás dado un salto evolutivo. Entrarás a un estado nuevo de consciencia dónde la paz y la armonía serán parte de tu vida cotidiana y sabrás que eres el único creador de tu realidad."
          />
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-2">
          <StoreItem
            title="Agenda 2023"
            description="Siembra tus intenciones."
            image={cuadernoImage}
            secondaryDescription="Lala pensó esta agenda con la intención de guiarte en tu trabajo interno y así vivir de manera consciente y equilibrada. La idea es que conozcas la energía disponible que son los momentos donde las puertas del cielo se abren para conectar con la energía que fluye desde el mundo superior. Este es el año para conectar con la creatividad, encontrar tus dones, y tus talentos."
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Store;
